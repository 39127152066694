.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.mine{
    font-weight: bold;
}

div.width {
    width: 200px;
}