/* Base styles for light and dark themes */
:root {
    --primary-color-light: #3498db;
    --primary-color-dark: #1d75b2;
    --accent-color-light: #f39c12;
    --accent-color-dark: #f39c12;
    --background-light: #ffffff;
    --background-dark: #101010;
    --text-light: #333333;
    --text-dark: #f5f5f5;
    --border-light: #ddd;
    --border-dark: #444;
    --button-bg-light: #3498db;
    --button-bg-dark: #1d75b2;
    --button-text-light: white;
    --button-text-dark: white;
    --cta-button-font-weight: bold;
    --shadow-light: rgba(0, 0, 0, 0.1);
    --shadow-dark: rgba(0, 0, 0, 0.3);
  }
  
  .landing-page {
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease;
    overflow-x: hidden;
  }
  
  .landing-page.light {
    background-color: var(--background-light);
    color: var(--text-light);
  }
  
  .landing-page.dark {
    background-color: var(--background-dark);
    color: var(--text-dark);
  }
  

  
  .header .logo {
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    letter-spacing: 2px;
  }
  
  .header .theme-toggle {
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid var(--primary-color-light);
    color: white;
    font-size: 1.1rem;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease-in-out;
  }
  
  .header .theme-toggle:hover {
    background-color: var(--accent-color-light);
    transform: scale(1.05);
  }
  
  .hero {
    text-align: center;
    padding: 75px 40px;
    background: linear-gradient(135deg, #ccd6d6, #4d5a66); /* Soft grey to dark charcoal gradient */
    color: white;
    border-bottom: 2px solid var(--border-light);
    box-shadow: 0 8px 24px var(--shadow-light);
  }
  
  .hero-title {
    font-size: 3.5rem;
    font-weight: 900;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  
  .hero-description {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  
  .cta-button {
    padding: 15px 30px;
    background-color: #ceb215;
    color: var(--button-text-light);
    font-size: 1.3rem;
    font-weight: var(--cta-button-font-weight);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0 8px 20px var(--shadow-light);
    transition: background-color 0.3s ease, transform 0.2s ease-in-out, box-shadow 0.3s ease;
  }
  
  .cta-button:hover {
    /*background-color: var(--button-bg-dark); */
    transform: translateY(-5px);
    box-shadow: 0 12px 30px var(--shadow-light);
  }
  
  .features {
    display: flex;
    justify-content: center;
    padding: 80px 40px;
    /*background-color: #f8f9fa; */
    gap: 40px;
  }
  
  .feature {
    max-width: 300px;
    text-align: center;
    padding: 40px;
    background-color: white;
    border-color: #ceb215;
    border-radius: 15px;
    box-shadow: 0 6px 15px var(--shadow-light);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 30px #ceb215;
  }
  
  .feature h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: black;
    margin-bottom: 15px;
  }
  
  .feature p {
    font-size: 1rem;
    color: var(--text-light);
    line-height: 1.5;
  }
  
  .feature-icon {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .footer {
    text-align: center;
    padding: 20px 40px;
    background-color: var(--background-light);
    border-top: 2px solid var(--border-light);
    box-shadow: 0 -4px 10px var(--shadow-light);
  }
  
  .footer p {
    font-size: 1rem;
    color: var(--text-light);
  }
  