.App {
  text-align: center;
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
  */
/*
:root {
   --background-color: #4f4f4f;
   --text-color: #000000;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

*/

span, ul {background-color: inherit;}

div:not(.ant-table-column-sorters,.ant-table-filter-column,.ant-tooltip-inner,.ant-tooltip-arrow,.ant-modal-root,.ant-modal-mask,.ant-modal-content) {background-color: inherit;}




body {
  /*background-color: #a8a8a8; */
  background-color: #ffffff;
  color: #000000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark {
  background-color: #312f2f;
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: auto;
  padding: 10px;
  z-index: 100;

}

.bannercontainer::after {
  content: "";
  display: table;
  clear: both;
}

.banner {
  height: 120px;
  top:0;
  float: left;
  z-index: 5;
  position: relative;
}

.banner.side {
  width: 15%;
}

.banner.main {
  width: 70%;

}

.banner.profile {
  width: 15%;
  text-align: right;

}

.module-header {
  text-align: left;
  background: grey;
  border-radius: 10px;
  margin-left: 10px;
}

.header-logo {
  height: 10vmin;
  pointer-events: none;
}

.header-logo-text {
  height: 10vmin;
  pointer-events: none;
}
.column {
  float: left;
  padding: 10px;
  margin-top: 30px;

}
.column.side {
  width: 20%;
}

.column.middle {
  width: 60%;
}

/*.row {
  position: relative;
  z-index: 0;
}*/

.row::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column.middle {
    width: 100%;
  }
  .column.side {
    width: 100%;
  }
  .banner.profile {
    direction: rtl;
  }
}



.module-list.content {
  margin-left:0
}

.ant-list {

  margin-left: 0;
  border-radius: 10px;
  padding: 2px;
}
.ant-list-items {
  text-align: left;
}

/* only needed for dark mode
.ant-menu, .ant-table, .ant-table-thead, .ant-table-thead>tr>th{
  background: inherit;
  background-color: inherit;
}

th.ant-table-cell.ant-table-column-sort {
  background: transparent;
  background-color: inherit;
}


*/



/*
NOTE: This doesnt work for the above because it is missing 'ant-table-cell' connection in sequence
th.ant-table-column-sort {
  background: transparent;
  background-color: inherit;
}
  */

  /*
  td.ant-table-column-sort {
    background-color: transparent;
  } */
  

 /* only needed for dark mode
 
 .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: inherit;
  } */